/* CSS */
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "../css/site.css";

// JS
import * as CookieConsent from 'vanilla-cookieconsent';
import Lenis from 'lenis';

// Alpine Objects
import "./components/app";
import Alpine from "alpinejs";
import collapse from '@alpinejs/collapse'

// import masonry
import MasonryGrid from "./components/masonry";


// ----------------------------------------------------------------

// Function to wait for gtag
function waitForGtag(callback, maxAttempts = 10, interval = 100) {
    let attempts = 0;

    function checkGtag() {
        attempts++;
        if (typeof gtag === 'function') {
            callback();
        } else if (attempts < maxAttempts) {
            setTimeout(checkGtag, interval);
        } else {
            console.warn('gtag not available after maximum attempts');
        }
    }

    checkGtag();
}



// function setConsent(){
//     // Set default consent preferences
//     let consentPreferences = {
//         analytics_storage: "denied",
//         ad_storage: "denied",
//         ad_user_data: "denied",
//         ad_personalization: "denied"
//     };

//     // Retrieve all the fields
//     const cookie = CookieConsent.getCookie();
//     const preferences = CookieConsent.getUserPreferences();

//     // In this example we're saving only 4 fields
//     const userConsent = {
//         consentId: cookie.consentId,
//         acceptType: preferences.acceptType,
//         acceptedCategories: preferences.acceptedCategories,
//         rejectedCategories: preferences.rejectedCategories
//     };

//     userConsent.acceptedCategories.forEach(category => {
//         if (category === 'analytics') {
//             consentPreferences.analytics_storage = "granted";
//         }
//         if (category === 'advertising') {
//             consentPreferences.ad_storage = "granted";
//             consentPreferences.ad_user_data = "granted";
//             consentPreferences.ad_personalization = "granted";
//         }
//     });

//     // Use waitForGtag to ensure gtag is available before calling it
//     waitForGtag(() => {
//         gtag("consent", "update", consentPreferences);
//     });
//     // gtag("consent", "update", consentPreferences);
// }


function setConsent(){
    // Set default consent preferences
    let consentPreferences = {
        analytics_storage: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        ad_personalization: "denied"
    };

    CookieConsent.getUserPreferences().acceptedCategories.forEach(category => {
        if (category === 'analytics') {
            consentPreferences.analytics_storage = "granted";
        }
        if (category === 'advertising') {
            consentPreferences.ad_storage = "granted";
            consentPreferences.ad_user_data = "granted";
            consentPreferences.ad_personalization = "granted";
        }
    });

    window.dataLayer = window.dataLayer || [];
    function gtag() {
        window.dataLayer.push(arguments);
    }
    gtag("consent", "update", consentPreferences);
}


window.CookieConsentConfig = {
    cookie: {
        name: 'cc_cookie',
    },
    guiOptions: {
        consentModal: {
            layout: 'box inline',
            position: 'bottom right',
        },
    },

    onFirstConsent: () => {
        setConsent();
    },
    onConsent: () => {
        setConsent();
    },
    onChange: () => {
        setConsent();
    },

    categories: {
        necessary: {
            readOnly: true,
            enabled: true
        },
        analytics: {
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/
                    }
                ]
            }
        },
        advertising: {
            autoClear: {
                cookies: [
                    {
                        name: /^(_ga|_gid)/
                    }
                ]
            }
        }
    },

    language: {
        default: 'nl',

        translations: {
            nl: {
                consentModal: {
                    title: '',
                    description: 'We gebruiken cookies om uw ervaring te verbeteren, websiteverkeer te analyseren en gepersonaliseerde inhoud te leveren. <a class=\"cc-link\" href=\"/cookiebeleid\">Cookiebeleid</a>.',
                    acceptAllBtn: 'Accepteren',
                    acceptNecessaryBtn: 'Afwijzen',
                    // footer: `
                    //     <a href="#link">Privacy Policy</a>
                    // `
                },
                preferencesModal: {
                    title: 'Cookie voorkeuren',
                    acceptAllBtn: 'Alle accepteren',
                    acceptNecessaryBtn: 'Alle afwijzen',
                    savePreferencesBtn: 'Voorkeuren opslaan',
                    closeIconLabel: 'Sluiten',
                    sections: [
                        {
                            title: 'Cookie usage',
                            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. For more details, refer to our <a href="#" class="cc__link">privacy policy</a>.'
                        }, {
                            title: "Strictly Necessary",
                            description: "These cookies are essential for the proper functioning of the website and cannot be disabled.",
                            linkedCategory: "necessary",
                            cookieTable: {
                                caption: "Cookie table",
                                headers: {
                                    name: "Name",
                                    domain: "Domain",
                                    description: "Description",
                                    expiration: "Expiration"
                                },
                                body: [{
                                    name: "CraftSessionId",
                                    domain: "This site",
                                    expiration: "Session",
                                    description: "This site relies on PHP sessions to maintain sessions across web requests. That is done via the PHP session cookie.",
                                    is_regex: !0
                                }, {
                                    name: "CRAFT_CSRF_TOKEN",
                                    domain: "This site",
                                    expiration: "Session",
                                    description: "Protects us and you as a user against Cross-Site Request Forgery attacks."
                                }, {
                                    name: "cc_cookie",
                                    domain: "This site",
                                    expiration: "6 months",
                                    description: "Records your cookie acceptance decision"
                                }]
                            }
                        }, {
                            title: 'Performance and analytics cookies',
                            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            linkedCategory: 'analytics'
                        }, {
                            title: 'Advertising cookies',
                            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                            linkedCategory: 'advertising'
                        }, {
                            title: 'More information',
                            description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc__link" href="#yourdomain.com">contact me</a>.',
                        }
                    ]
                }
            }
        }
    }
}
window.CookieConsent = CookieConsent;


const lenis = new Lenis();
function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}
requestAnimationFrame(raf)

let scrollTo = document.querySelectorAll('[data-scroll-to]');

scrollTo.forEach(element => {
    element.addEventListener('click', (e) => {
        e.preventDefault();
        let target = element.getAttribute('data-scroll-to');
        lenis.scrollTo(target);
    });
});


function masonryGrid() {
    const element = document.querySelector('.js-masonry');
    if (!!element) {
        element.classList.add('masonry-grid');

        // Get default value from data-columns-default attribute
        const defaultColumns = element.getAttribute('data-columns-default');

        const masonryGrid = new MasonryGrid(
            element,
            {
                '900': 2,
                '1200': 3,
                // only provide the default-property if defaultColumns is set in the data-columns-default attribute
                'default': defaultColumns ? defaultColumns : 4
            },
            {
                useViewportWidth: true,
                balanceThreshold: 100, // Adjust this value to change sensitivity of balancing
            }
        );
    }
}




// Start Alpine
Alpine.plugin(collapse);
window.Alpine = Alpine;
Alpine.start();

// DOM ready
document.addEventListener('DOMContentLoaded', () => {
    masonryGrid();
});